import "./index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";

import {
  duplicateEmailValidationRules,
  validationRules as signerDetailsValidationRules,
} from "common/transactions/form/sub_forms/signer_details/validators";
import {
  NotaryMeetingDetailsSection,
  validationRules as notaryMeetingDetailsValidationRules,
} from "common/transactions/form/sub_forms/notary_meeting_details";
import { Feature, OrganizationTypeEnum, ProofRequirementMfa } from "graphql_globals";
import { compact } from "util/object";
import AlertMessage from "common/core/alert_message";
import { DOCUMENT_UPLOADER_ERROR_MSG } from "constants/aria-describedby";
import { EVENT } from "constants/analytics";
import { getFormErrors, getFormValues, composeValidators } from "util/form";
import { MAX_SIGNERS } from "constants/transaction";
import { PageFrameSmallWithPadding, PageFramePadding } from "common/page_frame";
import { TransactionCreationPageSection } from "common/transaction_creation/section";
import { segmentTrack } from "util/segment";
import {
  SPLIT_BOOKMARKED_PDF_IN_TRANSACTION_UI,
  TRANSACTION_LEVEL_PAYER_CONFIGURATION,
} from "constants/feature_gates";
import { useFeatureFlag } from "common/feature_gating";
import { validateDateWindow } from "common/mortgage/transactions/signing_time_restrictions/signing_window";
import compose from "util/compose";
import EmailPreview from "common/transactions/form/sub_forms/customize_email/email_preview";
import { CustomizeEmailSection } from "common/transactions/form/sub_forms/customize_email";
import FooterSection from "common/transactions/form/footer";
import { DeprecatedFormRow } from "common/form/elements/row";
import { TransactionCreationPageContainer } from "common/transaction_creation";
import { TransactionCreationPageFooter } from "common/transaction_creation/footer";
import SignerDetailsSection from "common/transactions/form/sub_forms/signer_details";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import TransactionDocumentUploader from "common/document/uploader/transaction_uploader";
import EditPayment from "common/transactions/form/sub_forms/payment";
import { TransactionCreationPageHeader } from "common/transaction_creation/header";
import TransactionEditQuery from "common/transactions/graphql/queries/edit_query.graphql";
import { TransactionCreationV3Banner } from "common/transaction_creation/v3/form/banner";
import { scrollOnSubmitFail } from "util/scroll";
import { hasMissingSignerDesignations } from "common/transaction_creation/v3/validations/document_signatures";

import AnnotatePromptModal from "../annotate_prompt_modal";
import { formInvalid, minOneDocRequired, esignDocsRequired } from "../constants";

const UPLOAD_FILE_TYPES = ["PDF", "DOCX"];

function validate(values, props) {
  return composeValidators(
    validateDateWindow(values),
    duplicateEmailValidationRules(values, props),
    signerDetailsValidationRules(values, props),
    notaryMeetingDetailsValidationRules(values, props),
  )(values);
}

class TransactionEditForm extends PureComponent {
  constructor(props) {
    super(props);

    const {
      initialize,
      initialData,
      isProof,
      transaction: { customerSigners },
    } = this.props;
    // [BIZ-4243] Proof txns flag cleanup - make proof data the default

    const initialProofFormData = {
      ...initialData,
      signerDetails: initialData.signerDetails.map((signer) => {
        const matchingSigner = customerSigners.find((customer) => customer.id === signer.id);
        return {
          ...signer,
          ial2Proof: matchingSigner.proofRequirement?.ca?.selfie,
          smsAuthProof: matchingSigner.proofRequirement?.mfa?.type === ProofRequirementMfa.SMS,
          kbaProof: matchingSigner.proofRequirement?.kba,
        };
      }),
    };

    initialize(compact(isProof ? initialProofFormData : initialData), {
      keepValues: true, // prevents values initialized elsewhere from being cleared
    });
    this.state = {
      isAnnotating: false,
      showAnnotatePrompt: false,
      showAnnotateMustPrompt: false,
      annotateSaveError: null,
      isPreviewingEmail: false,
      showPaymentModal: false,
    };
  }

  canAnnotate = () => {
    const {
      valid,
      formValues: { signerDetails },
    } = this.props;
    const hasSigners =
      signerDetails && signerDetails.some((signer) => signer.firstName || signer.recipientGroup);
    return valid && hasSigners;
  };

  closeAnnotatePrompt = () => {
    this.setState({ showAnnotatePrompt: false, showAnnotateMustPrompt: false });
  };

  doAnnotate = () => {
    this.setState({ showAnnotatePrompt: false, showAnnotateMustPrompt: false, isAnnotating: true });
  };

  handleSendWithoutFields = () => {
    const { onSend, handleSubmit } = this.props;
    this.setState({ showAnnotatePrompt: false, showAnnotateMustPrompt: false });
    handleSubmit(onSend)();
  };

  handleSend = () => {
    const { onSend, handleSubmit, transaction, organization, paymentModalEnabled } = this.props;

    const documents = transaction.document_bundle.documents.edges.map(({ node }) => ({
      annotations: node.annotations,
      annotationDesignations: node.designations,
      customerCanAnnotate: node.customer_can_annotate,
    }));

    const annotationIsOn = documents.some((doc) => doc.customerCanAnnotate);
    const hasAnnotationsOrDesignations = documents.some(
      (doc) => doc.annotationDesignations.totalCount || doc.annotations.totalCount,
    );

    if (!organization.paymentSpecified && paymentModalEnabled) {
      this.setState({ showPaymentModal: true });
      return;
    }

    if (annotationIsOn && !hasAnnotationsOrDesignations) {
      this.setState({ showAnnotatePrompt: true, showAnnotateMustPrompt: false });
      return;
    }

    if (!annotationIsOn) {
      if (hasMissingSignerDesignations(documents, transaction.customerSigners)) {
        this.setState({ showAnnotatePrompt: false, showAnnotateMustPrompt: true });
        return;
      }
    }

    this.setState({ showAnnotatePrompt: false, showAnnotateMustPrompt: false });
    handleSubmit(onSend)();
  };

  getEmployeeName = () => {
    const { employee } = this.props;
    return employee.firstName && employee.lastName
      ? `${employee.firstName} ${employee.lastName}`
      : undefined;
  };

  checkDefaultEmailSignature = async () => {
    const { formValues, change } = this.props;
    const defaultSignature = this.getEmployeeName();
    if (defaultSignature === formValues.emailSignature) {
      await change("emailSignature", null);
    }
  };

  handleSave = async (withExit = true) => {
    const { onSaveAndClose, onSave, handleSubmit } = this.props;
    await this.checkDefaultEmailSignature();
    return withExit ? handleSubmit(onSaveAndClose)() : handleSubmit(onSave)();
  };

  handleOpenAnnotationModal = () => {
    const {
      transaction: {
        document_bundle: {
          documents: { edges: docs },
        },
      },
      handleSubmit,
      onSave,
    } = this.props;

    this.setState({ isAnnotating: false });

    // if transaction isn't ready to be annotated, we need to return a
    // rejected promise to TransactionDocumentUploader. We render the
    // error message in this component.
    if (!this.canAnnotate()) {
      const errorMessage = (
        <div className="DocumentUploaderAnnotateError">
          <FormattedMessage
            id="ef535788-2d6e-4db8-8fb9-6c4e11cafd15"
            defaultMessage="Please enter the required information above before filling in your {count, plural, one{document} other{documents}}."
            values={{ count: docs.length }}
          />
        </div>
      );
      this.setState({ annotateSaveError: errorMessage });
      return Promise.reject();
    }
    this.setState({ annotateSaveError: null });
    return handleSubmit(onSave)();
  };

  checkCanOpenAddDocumentModal = () => {
    if (!this.canAnnotate()) {
      const errorMessage = (
        <div id={DOCUMENT_UPLOADER_ERROR_MSG} className="DocumentUploaderAnnotateError">
          <FormattedMessage
            id="b00a2ba7-a2a8-45d1-b7f7-07b7f8ce77f0"
            defaultMessage="Please enter the required information above before uploading a document"
          />
        </div>
      );
      this.setState({ annotateSaveError: errorMessage });
      return Promise.reject();
    }

    this.setState({ annotateSaveError: null });
    return this.props.handleSubmit(this.props.onSave)();
  };

  handleCloseAnnotationModal = () => {
    const {
      transaction: { id },
    } = this.props;
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_ANNOTATE_DOCUMENTS_FINISHED, {
      organization_transaction_id: id,
    });
    this.setState({ isAnnotating: false });
  };

  onPreviewEmail = () => {
    const {
      transaction: { id },
    } = this.props;
    this.setState({ isPreviewingEmail: true });
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_PREVIEW_EMAIL, {
      organization_transaction_id: id,
    });
  };

  onClosePreview = () => {
    this.setState({ isPreviewingEmail: false });
  };

  emailPreviewFormValues(formValues) {
    const { signerDetails, subjectLine, customerNote, emailSignature } = formValues;

    let { firstName, middleName, lastName, customerEmail } = {};

    if (signerDetails) {
      const signer = signerDetails[0];

      firstName = signer.firstName;
      middleName = signer.middleName;
      lastName = signer.lastName;
      customerEmail = signer.email;
    }

    return {
      firstName,
      middleName,
      lastName,
      customerEmail,
      emailSubject: subjectLine,
      message: customerNote,
      emailSignature,
    };
  }

  // Validation Functions

  /**
   * Check if the send button in the footer should be disabled. There are a host of reasons it
   * could be disabled:
   * - If no documents have been uploaded
   */
  getSendDisabledReason = () => {
    const { disabledSubmit, invalid: formEntriesInvalid, transaction } = this.props;

    const documents = transaction.document_bundle.documents.edges.map(({ node }) => ({
      esign: node.esign,
      notarizationRequired: node.notarization_required,
      proofingRequired: node.proofing_required,
      customerCanAnnotate: node.customer_can_annotate,
      witnessRequired: node.witness_required,
    }));

    const hasEsignRequiredDocuments = documents.some((doc) => doc.esign);
    if (!documents.length) {
      return minOneDocRequired;
    } else if (!hasEsignRequiredDocuments) {
      return esignDocsRequired;
    } else if (formEntriesInvalid) {
      return formInvalid;
    } else if (disabledSubmit) {
      return esignDocsRequired;
    }

    return null;
  };

  closePaymentModal = () => {
    this.setState({ showPaymentModal: false });
  };

  render() {
    const {
      canSplitPDF,
      formErrors,
      formValues,
      isProof,
      openAnnotateModalAfterDocumentsUploaded,
      organization,
      transaction,
      viewer,
    } = this.props;
    const {
      showAnnotatePrompt,
      showAnnotateMustPrompt,
      isAnnotating,
      annotateSaveError,
      isPreviewingEmail,
      showPaymentModal,
    } = this.state;

    const { featureList } = organization;

    const canUseCustomEmail = featureList.includes(Feature.CUSTOM_EMAILS);
    const showSigningSchedule = organization.featureList.includes(Feature.SIGNING_SCHEDULE);
    const previewFormValues = isPreviewingEmail ? this.emailPreviewFormValues(formValues) : {};
    const canRequireProofing = organization.canRequireVerificationOfFact;

    const defaultDocRequirements = {
      esign: true,
      notarizationRequired: false,
      proofingRequired: false,
      signingRequiresMeeting: false,
    };
    const defaultDocPermissions = {
      witnessRequired: false,
    };

    const saveDisabledReason = this.getSendDisabledReason();
    const sendDisabledReason = this.getSendDisabledReason();

    const disableTemplateUpload =
      organization.organizationType === OrganizationTypeEnum.TITLE_AGENCY &&
      organization.templates.totalCount === 0;
    return (
      <div className="EsignTransactionEditForm">
        <TransactionCreationV3Banner
          transactionId={transaction.id}
          userId={viewer.user.id}
          save={this.props.dirty ? () => this.handleSave(false) : false}
          optedOut
        />

        {showAnnotatePrompt || showAnnotateMustPrompt ? (
          <AnnotatePromptModal
            onAnnotate={this.doAnnotate}
            onComplete={this.handleSendWithoutFields}
            onCancel={this.closeAnnotatePrompt}
            showAnnotatePrompt={showAnnotatePrompt}
          />
        ) : (
          showPaymentModal && (
            <EditPayment onCancel={this.closePaymentModal} onComplete={this.handleSend} />
          )
        )}
        {transaction.duplicatedTransaction && (
          <AlertMessage kind="warning" data-automation-id="duplicate-transaction-banner" centerText>
            <FormattedMessage
              id="bedb6e88-974e-4dea-b817-f43dddb1979b"
              defaultMessage="This is a duplicate transaction."
            />
          </AlertMessage>
        )}
        <PageFrameSmallWithPadding>
          <div className="EsignTransactionEditForm--content">
            <TransactionCreationPageContainer>
              <TransactionCreationPageHeader type={isProof ? "proof" : "esign"} />

              <TransactionCreationPageSection
                iconName="employees"
                title={
                  <FormattedMessage
                    id="10d427b3-842a-43f6-9648-814254b0ab1a"
                    defaultMessage="Signer details"
                  />
                }
              >
                <SignerDetailsSection
                  formErrors={formErrors}
                  formType={isProof ? "proof" : "esign"}
                  formName="EditTransaction"
                  organization={organization}
                  hideSecondaryId
                  maxSigners={MAX_SIGNERS}
                  displayCosignerEmail
                />
              </TransactionCreationPageSection>

              {showSigningSchedule && (
                <TransactionCreationPageSection
                  iconName="calendar"
                  title={
                    <FormattedMessage
                      id="f60012e7-85f6-479a-807c-0ff48d72fbb0"
                      defaultMessage="Signing schedule"
                    />
                  }
                >
                  <NotaryMeetingDetailsSection
                    formName="EditTransaction"
                    organization={organization}
                    allowCloserAssignment={false}
                    showSigningWindow
                    showNotaryMeetingDate={false}
                    showPersonallyKnownToNotary={false}
                  />
                </TransactionCreationPageSection>
              )}
              <TransactionCreationPageSection
                iconName="document-doc"
                title={
                  <FormattedMessage
                    id="2e583751-cd51-4c1b-84c6-773a8201a9ac"
                    defaultMessage="Document upload"
                  />
                }
              >
                {annotateSaveError && (
                  <DeprecatedFormRow className="DocumentUploaderAnnotateError" noMargin>
                    {annotateSaveError}
                  </DeprecatedFormRow>
                )}
                <SubForm>
                  <SubFormSection fullWidth>
                    <DeprecatedFormRow noMargin>
                      <TransactionDocumentUploader
                        className="BusinessTransactionDocumentUploader"
                        canSetDocRequirements={false}
                        showWitnessRequired={false}
                        defaultDocRequirements={defaultDocRequirements}
                        defaultDocPermissions={defaultDocPermissions}
                        transaction={transaction}
                        organization={organization}
                        viewer={viewer}
                        canRequireProofing={canRequireProofing}
                        supportedFileTypes={UPLOAD_FILE_TYPES}
                        onOpenAnnotateModal={this.handleOpenAnnotationModal}
                        checkCanOpenAddDocumentModal={this.checkCanOpenAddDocumentModal}
                        showAcceptedDocuments={false}
                        disableTemplateUpload={disableTemplateUpload}
                        annotatingByPrompt={isAnnotating}
                        onCloseAnnotateModal={this.handleCloseAnnotationModal}
                        openAnnotateModalAfterDocumentsUploaded={
                          openAnnotateModalAfterDocumentsUploaded
                        }
                        splitBookmarkedPdf={canSplitPDF}
                        transactionQuery={TransactionEditQuery}
                      />
                    </DeprecatedFormRow>
                  </SubFormSection>
                </SubForm>
              </TransactionCreationPageSection>
              <TransactionCreationPageSection
                iconName={canUseCustomEmail && "email"}
                title={
                  canUseCustomEmail && (
                    <FormattedMessage
                      id="f6c83f92-d216-4bf4-91b4-3e27d8f9c02d"
                      defaultMessage="Custom email"
                    />
                  )
                }
              >
                <CustomizeEmailSection
                  formName="EditTransaction"
                  onPreviewEmail={this.onPreviewEmail}
                  defaultSignature={this.getEmployeeName()}
                  isLocked={!canUseCustomEmail}
                />
                {isPreviewingEmail && (
                  <EmailPreview
                    onRequestClose={this.onClosePreview}
                    organization={organization}
                    transaction={transaction}
                    formValues={previewFormValues}
                  />
                )}
              </TransactionCreationPageSection>
            </TransactionCreationPageContainer>
          </div>
        </PageFrameSmallWithPadding>
        <TransactionCreationPageFooter>
          <PageFramePadding className="TransactionEditForm--footer">
            <FooterSection
              sendDisabledReason={sendDisabledReason}
              saveDisabledReason={saveDisabledReason}
              onSend={this.handleSend}
              onSave={this.handleSave}
            />
          </PageFramePadding>
        </TransactionCreationPageFooter>
      </div>
    );
  }
}

TransactionEditForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  disabledSubmit: PropTypes.bool,
  isProof: PropTypes.bool,
  transaction: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  viewer: PropTypes.shape({
    user: PropTypes.shape({
      organization: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  openAnnotateModalAfterDocumentsUploaded: PropTypes.bool.isRequired,
  /** The initial data to put in the form */
  initialData: PropTypes.object.isRequired,

  // ReduxForm
  initialize: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
};

let saveResolver;
export default compose(
  reduxForm({
    form: "EditTransaction",
    validate,
    onSubmitFail: () => {
      saveResolver?.("failed");
      scrollOnSubmitFail();
    },
    onSubmitSuccess: () => {
      saveResolver?.("saved");
    },
  }),
  getFormErrors("EditTransaction"),
  getFormValues("EditTransaction"),
)((props) => {
  const handleSubmit = (onSubmit) => {
    return () => {
      return new Promise((resolve) => {
        saveResolver = resolve;
        props.handleSubmit(onSubmit)();
      });
    };
  };
  const paymentModalForTransaction =
    props.isProof || props.organization.activeTier.prices.esignedBundle > 0;
  const canSplitPDF =
    useFeatureFlag(SPLIT_BOOKMARKED_PDF_IN_TRANSACTION_UI) || props.transaction.splitBookmarkedPdf;
  return (
    <TransactionEditForm
      {...props}
      handleSubmit={handleSubmit}
      canSplitPDF={canSplitPDF}
      paymentModalEnabled={
        paymentModalForTransaction && !useFeatureFlag(TRANSACTION_LEVEL_PAYER_CONFIGURATION)
      }
    />
  );
});
